// SCSS variable configuration to include LBO related CSS specific to this locale.
$lbo-enabled: true;

// Order Groove configuration
$og-enabled: true;

// Enable Appointment Booking Reskin 2019.
$ab_skin_v2: true;

$brandon-text: Brandon Text, Microsoft Jhenghei, PingFang, sans-serif;
$brandon-text-regular: $brandon-text;
$brandon-text-medium: Brandon Text Medium, Microsoft Jhenghei, PingFang, sans-serif;
$brandon-text-bold: Brandon Text Bold, Microsoft Yahei UI, sans-serif;
$base-font-family: $brandon-text;
$header-font-family: $brandon-text-bold;
$roboto-text: 'roboto-mono_regular', Microsoft Jhenghei, PingFang, Helvetica, Arial, sans-serif;
$bb-sans: BBSans Regular, Microsoft Yahei UI, PingFang, Helvetica, Arial, sans-serif;

$regional_shared_account_signin: true;
$regional_shared_password_strength: true;
$perlgem_shared_account_settings: true;

$gnav-category-link-letter-spacing: 0.03em;
$typography-headline--xl-letter-spacing: 0.03em;
$typography-headline--l-letter-spacing: 0.03em;
$typography-headline--m-letter-spacing: 0.03em;

$cr22: true;

$cr24: true;
