@import '../../../../scss/theme-bootstrap';

.content-formatter {
  &.content-formatter--no-text {
    @include breakpoint($medium-up) {
      .content-formatter__rendered > .content-block--basic-tout {
        .content-block__content .content-block__content-text .content-block__media,
        .padding--bare {
          padding-top: 0;
        }
      }
    }
  }
  .content-formatter__rendered > .content-block--basic-tout {
    height: auto;
    .content-block--text.align-#{$ldirection} + .align-links--bare {
      text-align: center;
      @include breakpoint($medium-up) {
        text-align: #{$ldirection};
      }
    }
    .content-block__content {
      &.vertical-alignment--bare {
        display: block;
        justify-content: flex-start;
      }
      .content-block__content-text {
        .content-block__media {
          padding: 0 0 1rem;
        }
        .content-block__line--content--1 {
          margin: 1rem auto 0;
          .custom-text,
          p {
            font-family: $body-font-bold;
            font-size: 16px;
            letter-spacing: 2px;
            line-height: 20px;
          }
        }
        .content-block__line--content--2 {
          margin: 0.75rem auto 0;
          .custom-text,
          p {
            font-family: $body-font;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 22px;
          }
        }
        .content-block__content-buttons {
          font-family: $body-font;
          font-size: 12px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .content-formatter__rendered > .content-block--basic-tout.content-block--filter-set-tip {
    height: 100%;
  }
  .content-formatter__content--no-gutters {
    .content-block--basic-tout {
      padding-bottom: 0;
      .content-block__content-text.padding--bare {
        @include breakpoint($medium-up) {
          padding: 0 14px;
        }
      }
    }
  }
  &__picture {
    position: absolute;
    #{$ldirection}: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__text {
    &.padding {
      &--bare {
        padding-bottom: 25px;
      }
    }
  }
  .content-block {
    &__content-text {
      &.padding {
        &--bare {
          padding: 0 14px;
        }
      }
    }
  }
}
